import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import websocket from './modules/websocket';


export default createStore({
  modules: {
    auth,
    websocket,
  },
  state: {
    phone: null,
    token: null,
    patientName: ''
  },
  mutations: {
    setPhone(state, phone) {
      state.phone = phone;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPatientName(state, name) {
      state.patientName = name;
    }
  },

  getters: {
    phone: state => state.phone,
    token: state => state.token,
  },
  plugins: [createPersistedState()],
});
