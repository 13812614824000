<template>
    <div>
      <h1>Launching Application...</h1>
      <p>Please wait while we process your session.</p>
    </div>
  </template>
  
<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const launch = urlParams.get('launch');
  const iss = urlParams.get('iss');

  if (!launch || !iss) {
    console.error('Required parameters (launch or iss) are missing from the URL.');
    return;
  }

  const allowedIssList = [
    'https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4',
  ];

  if (!allowedIssList.includes(iss)) {
    console.error('ISS value is not in the allowlist. Potential phishing attempt.');
    return;
  }

  const sendToBackend = async () => {
    try {
      const response = await fetch('https://gpt-stage.ensofia.app:8443/ehr_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ launch, iss }),
      });

      if (!response.ok) {
        throw new Error('Failed to send data to the backend.');
      }

      const responseData = await response.json();
      console.log('Backend response:', responseData);
    } catch (error) {
      console.error('Error sending data to the backend:', error.message);
    }
  };

  sendToBackend();
});
</script>



