import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';


import UserRegister from '@/pages/Auth/UserRegister.vue';
import UserLogin from '@/pages/Auth/UserLogin.vue';
// import EncountersList from '@/pages/encountersList.vue';
import TranslatePage from '@/pages/TranslatePage.vue';
import RecordPage from '@/pages/RecordPage.vue';
import OTP from '@/pages/Auth/OTP.vue';
import ForgetPassword from '@/pages/Auth/ForgetPassword.vue';
import ResetPassword from '@/pages/Auth/resetPassword.vue';
import EnconterDetails from '@/pages/enconterDetails.vue';
import AddEnconter from '@/pages/addEnconter.vue';
import StreamTest from '@/pages/streamTest.vue';
import AppLauncher from '@/pages/Auth/AppLauncher.vue';

const routes = [
  { path: '/register', component: UserRegister },
  { path: '/login', component: UserLogin },
  { path: '/applauncher', component: AppLauncher },
  // { path: '/encountersList', component: EncountersList, meta: { requiresAuth: true } },
  { path: '/translate',
    name: 'translate',
    component: TranslatePage , meta: { requiresAuth: true }},
  { path: '/record',
    name: 'record',
    component: RecordPage, meta: { requiresAuth: true } },
  { path: '/enconter/:chatId',
    name: 'enconter',
    component: EnconterDetails , meta: { requiresAuth: true }},
  { path: '/addEnconter',
      name: 'addEnconter',
      component: AddEnconter , meta: { requiresAuth: true }},
  { path: '/forget-password', component: ForgetPassword },
  { path: '/stream', component: StreamTest },
  { path: '/reset-password', component: ResetPassword },
  { path: '/otp',name: 'OtpPage', component: OTP },
  
  { path: '/', redirect: '/addEnconter' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  // const userRole = store.getters['auth/userRole'];

  //Prevent access to login and register pages if user is authenticated
  if (isAuthenticated && ['/login', '/register'].includes(to.path)) {
    next('/'); 
    return;
  }
  // Stop routing to OTP page except from Register or Forget Password
  if (to.path === '/otp' && !['/register', '/forget-password' , '/login'].includes(from.path)) {
    next('/'); 
    return;
  }

  //  Stop routing to Reset Password except from OTP page
  if (to.path === '/reset-password' && from.path !== '/otp') {
    next('/otp'); 
    return;
  }
  //  Stop routing to recored except from addEnconter page
  if (to.path === '/record' && from.path !== '/addEnconter') {
    next('/addEnconter'); 
    return;
  }
  if (to.path === '/translate' && from.path !== '/addEnconter') {
    next('/addEnconter'); 
    return;
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  // } else if (to.meta.roles && !to.meta.roles.includes(userRole)) {
  //   next('/login'); // Redirect to a "not authorized" or a safe page
  } else {
    next();
  }
});


export default router;
